<template>
  <base-nav
    v-model="showMenu"
    class="top-navbar"
    type="dark"
    :transparent="true"
  >
    <div slot="brand" class="navbar-wrapper">
      <!-- <div class="navbar-minimize d-inline"><sidebar-toggle-button /></div> -->
      <div
        class="navbar-toggle d-inline"
        :class="{ toggled: $sidebar.showSidebar }"
      >
        <button type="button" class="navbar-toggler" @click="toggleSidebar">
          <span class="navbar-toggler-bar bar1"></span>
          <span class="navbar-toggler-bar bar2"></span>
          <span class="navbar-toggler-bar bar3"></span>
        </button>
      </div>
      <!-- <a class="navbar-brand" href="">{{ routeName }}</a> -->
    </div>

    <ul class="navbar-nav" :class="$rtl.isRTL ? 'mr-auto' : 'ml-auto'">
      <base-dropdown
        tag="li"
        :menu-on-right="!$rtl.isRTL"
        title-tag="a"
        class="nav-item"
        title-classes="nav-link"
        menu-classes="dropdown-navbar"
      >
        <template
          slot="title"
        >
        <!-- <img class="avatar" :src="'data:image/png;base64,' + user.base64ImageData" alt="..." /> -->

          <div class="photo"><img :src="'data:image/png;base64,' + userImage" /></div>
          <b class="caret d-none d-lg-block d-xl-block"></b>
          <p v-if="isMobile" class="mobile-navbar-label">{{$t('sidebar.user')}}</p>
        </template>
        <li class="nav-link">
          <a href="#/userProfile" class="nav-item dropdown-item">{{$t('sidebar.userProfile')}}</a>
        </li>
        <li class="nav-link">
          <a href="#/analytics/general" class="nav-item dropdown-item">{{$t('sidebar.generalAnalytics')}}</a>
        </li>
        <li class="nav-link">
          <a href="#/settings/appsettings" class="nav-item dropdown-item">{{$t('sidebar.settings')}}</a>
        </li>
        <div class="dropdown-divider"></div>
        <li class="nav-link">
          <a href="#" class="nav-item dropdown-item" @click="logout">{{$t('sidebar.logOut')}}</a>
        </li>
      </base-dropdown>
    </ul>
  </base-nav>
</template>
<script>
import { BaseNav } from '@/components';
import { EventBus } from '../../components/event-bus.js';
import api from "../../services/api"
import authService from '../../services/authService';
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { defaultUserImage } from '../../components/Image Bytes/imagebytes'


export default ({
  components: {
    BaseNav,
  },
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
    isRTL() {
      return this.$rtl.isRTL;
    }
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: '',
      searchLength: 0,
      options: [],
      value: {},
      userImage: '',
      isMobile: window.innerWidth <= 768,
    };
  },
  created() {
    const decodedToken = jwtDecode(Cookies.get('accessToken'));
    const CompanyName = `${decodedToken.CustomerName}`;
    const UserRole = `${decodedToken.role}`;
    let userImage = sessionStorage.getItem('userImage');
    if (userImage !== null) {
      this.userImage = userImage;
    }
    else {
      let userUrl = `/User/${decodedToken.UserIdentifier}`;
      api.get(userUrl)
        .then(response => {
          this.userImage = response.data.userImageThumbnailBytes !== null ? response.data.userImageThumbnailBytes: defaultUserImage;
          sessionStorage.setItem('userImage', this.userImage);
        })
        .catch(error => {
          console.error('API request error:', error);
          this.$notify({
            message: "Unable to fetch user information",
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
            timeout: 2000,
          });
        });
    }


    EventBus.$on('userImageUpdated', (newImage) => {
      this.userImage = newImage;
      sessionStorage.setItem('userImage', this.userImage);
    });
  },
  methods: {
    handleSearch(query, cb) {
      // We never receive query = '' so no need to exclude query.length = 0
      console.log("Trigger");
      console.log(query);
      if ((query.length % 2 === 0 && this.searchLength < query.length) || (query.length > 2 && this.options.length === 0)) {
        console.log("1");
        this.searchLength = query.length;
        this.fetchSuggestions(query, cb);
      }
      // Clear suggestions and fetch new ones if backspace is triggered (We might want to adjust this to only clear cache if it becomes to heavy)
      else if (this.searchLength > query.length && query.length > 1) {
        console.log("2");
        this.searchLength = query.length;
        this.clearSuggestions(query, cb);
      }
      else {
        // Filter in memory the previous result if the query contains more than 2 letters since we clear suggestions if its lower (backspace)
        if (query.length > 2) {
          console.log("3");
          this.searchLength = query.length;
          this.options = this.options.filter(item => {
          return item.suggestion.toLowerCase()
            .indexOf(query.toLowerCase()) > -1;
          });
        }
        // Clear suggestions and do not fetch new suggestions
        else {
          console.log("4");
          this.clearSuggestions('', cb);
          this.searchLength = query.length;
          this.options = [];
        }
        cb(this.options);
      }
    },
    fetchSuggestions(query, cb) {
      let getSearchUrl = `/Search/Suggestions?text=${query}&includeLocations=false`;
      api.get(getSearchUrl)
      .then(response => {
        this.options = response.data.filter(item => {
          return item.suggestion.toLowerCase()
            .indexOf(query.toLowerCase()) > -1;
        });
        cb(this.options)
      })
      .catch(error => {
          console.error('API request error:', error);
          this.options = [];
          cb(this.options);
      });
    },
    clearSuggestions(query, cb) {
      let clearSearchUrl = "/Search/ClearCache";
      api.delete(clearSearchUrl)
      .then(response => {
        if (query.length != 0) {
          this.fetchSuggestions(query, cb);
        }
      })
      .catch(error => {
          console.error('API request error:', error);
          this.options = [];
          if (cb !== null) {
            cb(this.options);
          }
      });
    },
    handleOptionSelect(item) {
      let routingObj = null;
      switch (item.type) {
        case "Tool":
          routingObj = { name: item.type, params: { toolId: item.id} };
          break;
        case "Project":
          routingObj = { name: item.type, params: { projectId: item.id} };
          break;
        case "ToolGroup":
          routingObj = { name: "Car", params: { toolGroupId: item.id} };
          break;
        case "User":
          routingObj = { name: item.type, params: { userId: item.id} };
          break;
        default:
          this.$notify({
            message: `Record ${item.suggestion} not found`,
            icon: "tim-icons icon-time-alarm",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "warning",
            timeout: 3000,
          });
      }
      // Reset the search values since we have navigated to a new page or the previous search was unsuccessful
      this.options = [];
      this.searchLength = 0;
      this.searchQuery = '';
      this.searchModalVisible = false;
      this.clearSuggestions('', null);
      this.$router.push(routingObj);
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    logout() {
      authService.logout();
      Cookies.remove('accessToken'); 
      Cookies.remove('refreshToken');
      this.$router.push('/login');
    },
  }
});
</script>
<style scoped>
.top-navbar {
  top: 0px;
}
.expand {
  width: 100%;
}
.mobile-navbar-label{
  color: white;
  font-weight:normal;
}
</style>
