<template>
  <div class="row">
    <div class="col-12">
      <div v-if="showScannerCard">
        <card :title="$t('scanning.scannedTools')" :onCancel="cancel">
          <input ref="scannerInput" @input="onScannerInput" type="text" class="form-control" autofocus :placeholder="$t('scanning.scanOrTypeHere')" />
          <div class="row">
            <div class="col-4" v-for="(tool, index) in scannedTools" :key="index">
              <card :title=tool.name :onCancel="() => removeTool(index)">
                <img class="tool-thumb" :src="'data:image/webp;base64,' +  tool.toolImageThumbnailBytes" alt="Tool Image" />
              </card>
            </div>
          </div>
          <base-button class="col-12" native-type="submit" type="default" @click="handleSubmit">{{$t('common.submit')}}</base-button>
        </card>
      </div>
      <base-button class="col-12" v-else native-type="submit" type="default" @click="handleScannerClick"><img src="../../public/img/barcode-scanner.png" alt="scanner" width="50" height="50"></base-button>
    </div>
  </div>
</template>

<script>
import api from '../services/api';
export default {
  data() {
    return {
      showScannerCard: false,
      scannedTools: [],
      scannerInput: '',
    };
  },
  props: {
    onSubmit: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    async onScannerInput(event){
      this.scannerInput = event.target.value;
      var newVal = this.scannerInput.replace(/\r?\n|\r/g, '');
      newVal = newVal.split("/").pop();
      this.scannerInput = newVal;
      //console.log('rass' + event.target.value);
      
      if (this.validateUuid(newVal)) {
        await this.fetchTool(newVal); //need to wait so that the function finalizes before clearing the field
        this.scannerInput = ''; // Clear the field
      }
    },
    handleScannerClick() {
      this.showScannerCard = true;
      this.$nextTick(() => {
        this.$refs.scannerInput.focus();
      });
    },
    handleSubmit() {
      this.onSubmit(this.scannedTools);
      this.showScannerCard = false;
      this.scannedTools = [];
      this.scannerInput = '';
    },
    async fetchTool(uuid) {
      const isScanned = this.scannedTools.some(tool => tool.id === uuid);
      if (isScanned) {
        this.$notify({
          message: await this.$t('scanning.alreadyScanned'),
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "danger",
          timeout: 2000,
        });
        return;
      }
      api.get(`/Tool/${uuid}`)
      .then(response => {
        if(response.data.toolReservations && response.data.toolReservations.length > 0){
          this.scannedTools.push(JSON.parse(JSON.stringify(response.data)));
          this.$notify({
            message: this.$t('scanning.toolFound'),
            icon: "tim-icons icon-check-2",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "success",
            timeout: 1000,
          });
        }
        else{
          this.$notify({
          message: this.$t('scanning.toolNotInReservedToolList'),
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "danger",
          timeout: 2000,
        });
        }
      })
      .catch(error => {
        console.error('API request error:', error);
        this.$notify({
          message: this.$t('common.unableToRetrieveData'),
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "danger",
          timeout: 2000,
        });
      });
    },
    cancel() {
      this.showScannerCard = false;
    },
    removeTool(index) {
      this.scannedTools.splice(index, 1);
    },
    validateUuid(uuid) {
      const uuidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
      return uuidRegex.test(uuid);
    },
  },
};
</script>
<style scoped>
.tool-thumb {
  width: 50; 
  height: 50;
}
</style>
