<template>
  <div class="sidebar" :data="actualBackgroundColor">
    <div class="sidebar-wrapper" ref="sidebarScrollArea">
      <div class="logo">
        <!-- <a href="" class="simple-text logo-mini"> -->
          <img :src="logo" alt="app-logo" />
      </div>
      <slot></slot>
        
      <div class="nav">
        <slot name="links">
          <sidebar-item
            v-for="(link, index) in sidebarLinks"
            :key="link.name + index"
            :link="link"
          >
            <sidebar-item
              v-for="(subLink, index) in link.children"
              :key="subLink.name + index"
              :link="subLink"
            >
            </sidebar-item>
          </sidebar-item>
        </slot>
      </div>
    </div>
  </div>
</template>
<script>
import api from "../../services/api"

export default {
  name: 'sidebar',
  props: {
    title: {
      type: String,
      default: 'Jón Jónsson',
      description: 'Sidebar title'
    },
    shortTitle: {
      type: String,
      default: 'CT',
      description: 'Sidebar short title'
    },
    logo: {
      type: String,
      default: 'img/sidebar-header-tolspor2.png',
      description: 'Sidebar app logo'
    },
    backgroundColor: {
      type: String,
      default: 'tolsporagulur',
      validator: value => {
        let acceptedValues = [
          '',
          'vue',
          'blue',
          'green',
          'orange',
          'red',
          'primary',
          'tolsporagulur'
        ];
        return acceptedValues.indexOf(value) !== -1;
      },
      description:
        'Sidebar background color (vue|blue|green|orange|red|primary|tolsporagulur)'
    },
    sidebarLinks: {
      type: Array,
      default: () => [],
      description:
        "List of sidebar links as an array if you don't want to use components for these."
    },
    autoClose: {
      type: Boolean,
      default: true,
      description:
        'Whether sidebar should autoclose on mobile when clicking an item'
    }
  },
  data() {
    return {
      customerName: 'Jón Jónsson', // default value
      actualBackgroundColor: this.backgroundColor, // new data property
      searchQuery: '',
      searchLength: 0,
      options: [],
      value: {},
      isMobile: false
    };
  },
  created() {
    this.actualBackgroundColor = localStorage.getItem('selectedColor') || 'tolsporagulur';
    window.addEventListener('resize', this.checkMobile);
    this.checkMobile();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkMobile);
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false;
    }
  },
  provide() {
    return {
      autoClose: this.autoClose
    };
  },
  methods: {
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    },
    handleSearch(query, cb) {
      // We never receive query = '' so no need to exclude query.length = 0
      console.log("Trigger");
      console.log(query);
      if ((query.length % 2 === 0 && this.searchLength < query.length) || (query.length > 2 && this.options.length === 0)) {
        console.log("1");
        this.searchLength = query.length;
        this.fetchSuggestions(query, cb);
      }
      // Clear suggestions and fetch new ones if backspace is triggered (We might want to adjust this to only clear cache if it becomes to heavy)
      else if (this.searchLength > query.length && query.length > 1) {
        console.log("2");
        this.searchLength = query.length;
        this.clearSuggestions(query, cb);
      }
      else {
        // Filter in memory the previous result if the query contains more than 2 letters since we clear suggestions if its lower (backspace)
        if (query.length > 2) {
          console.log("3");
          this.searchLength = query.length;
          this.options = this.options.filter(item => {
          return item.suggestion.toLowerCase()
            .indexOf(query.toLowerCase()) > -1;
          });
        }
        // Clear suggestions and do not fetch new suggestions
        else {
          console.log("4");
          this.clearSuggestions('', cb);
          this.searchLength = query.length;
          this.options = [];
        }
        cb(this.options);
      }
    },
    fetchSuggestions(query, cb) {
      let getSearchUrl = `/Search/Suggestions?text=${query}&includeLocations=false`;
      api.get(getSearchUrl)
      .then(response => {
        this.options = response.data.filter(item => {
          return item.suggestion.toLowerCase()
            .indexOf(query.toLowerCase()) > -1;
        });
        cb(this.options)
      })
      .catch(error => {
          console.error('API request error:', error);
          this.options = [];
          cb(this.options);
      });
    },
    clearSuggestions(query, cb) {
      let clearSearchUrl = "/Search/ClearCache";
      api.delete(clearSearchUrl)
      .then(response => {
        if (query.length != 0) {
          this.fetchSuggestions(query, cb);
        }
      })
      .catch(error => {
          console.error('API request error:', error);
          this.options = [];
          if (cb !== null) {
            cb(this.options);
          }
      });
    },
    handleOptionSelect(item) {
      let routingObj = null;
      switch (item.type) {
        case "Tool":
          routingObj = { name: item.type, params: { toolId: item.id} };
          break;
        case "Project":
          routingObj = { name: item.type, params: { projectId: item.id} };
          break;
        case "ToolGroup":
          routingObj = { name: "Car", params: { toolGroupId: item.id} };
          break;
        case "User":
          routingObj = { name: item.type, params: { userId: item.id} };
          break;
        default:
          this.$notify({
            message: `Record ${item.suggestion} not found`,
            icon: "tim-icons icon-time-alarm",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "warning",
            timeout: 3000,
          });
      }
      // Reset the search values since we have navigated to a new page or the previous search was unsuccessful
      this.options = [];
      this.searchLength = 0;
      this.searchQuery = '';
      this.clearSuggestions('', null);
      this.$router.push(routingObj);
    },
    checkMobile() {
      this.isMobile = window.innerWidth < 600;
    }
  },
};
</script>
<style>
@media (min-width: 992px) {
  .navbar-search-form-mobile,
  .nav-mobile-menu {
    display: none;
  }
}

.autocomplete-width {
  width: 100%;
  padding: 0.5rem 0.7rem 1rem;
}

.el-autocomplete-suggestion {
  min-width: 230px !important;
}
</style>
