<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <notifications></notifications>
    <!-- <sidebar-fixed-toggle-button /> -->
    <side-bar
      :background-color="sidebarBackground"
      :short-title="$t('sidebar.shortTitle')"
      :title="$t('sidebar.title')"
    >
      <template slot="links">
        <sidebar-item
          :link="{
            name: $t('sidebar.mytools'),
            icon: 'tim-icons icon-shape-star',
            path: '/mytools',
          }"
          v-if="userRole !== 'WarehouseUser'"
        ></sidebar-item>
        <!-- <sidebar-item
          :link="{
            name: $t('sidebar.dashboard'),
            icon: 'tim-icons icon-chart-pie-36',
            path: '/dashboard'
          }"
        >
        </sidebar-item> -->
        <sidebar-item
          :link="{
            name: $t('sidebar.tools'),
            icon: 'tim-icons icon-settings',
            path: '/tools',
          }"
          v-if="userRole !== 'WarehouseUser'"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: $t('sidebar.cars'),
            icon: 'tim-icons icon-bus-front-12',
            path: '/cars',
          }"
          v-if="userRole !== 'User' && userRole !== 'WarehouseUser'"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: $t('sidebar.projects'),
            icon: 'tim-icons icon-bullet-list-67',
            path: '/projects',
          }"
          v-if="userRole !== 'WarehouseUser'"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: $t('sidebar.locations'),
            icon: 'tim-icons icon-square-pin',
            path: '/locations',
          }"
          v-if="userRole !== 'User' && userRole !== 'WarehouseUser'"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: $t('sidebar.users'),
            icon: 'fa fa-users',
            path: '/users',
          }"
          v-if="userRole !== 'WarehouseUser'"

        ></sidebar-item>
        <sidebar-item
          :link="{
            name: $t('sidebar.unreserve'),
            icon: 'tim-icons icon-settings',
            path: '/warehouseunreserve',
          }"
          v-if="userRole === 'WarehouseUser'"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: $t('sidebar.userProfile'),
            icon: 'tim-icons icon-badge',
            path: '/userProfile',
          }"
          v-if="userRole !== 'WarehouseUser'"

        ></sidebar-item>
        <sidebar-item
          :link="{
            name: $t('sidebar.generalAnalytics'),
            icon: 'tim-icons icon-sound-wave',
          }"
          v-if="userRole !== 'User' && userRole !== 'WarehouseUser'"
        >
          <sidebar-item
            :link="{
              name: $t('sidebar.analytics'),
              icon: 'tim-icons icon-chart-pie-36',
              path: '/analytics/general',
            }"
          ></sidebar-item>
          <sidebar-item
            :link="{
              name: $t('sidebar.costReport'),
              icon: 'tim-icons icon-money-coins',
              path: '/analytics/costreport',
            }"
          ></sidebar-item>
          <sidebar-item
            :link="{
              name: $t('sidebar.leafletMaps'),
              icon: 'tim-icons icon-world',
              path: '/analytics/map',
            }"
        ></sidebar-item>
        </sidebar-item>
        <sidebar-item
          :link="{
            name: $t('sidebar.settings'),
            icon: 'tim-icons icon-settings-gear-63'
          }"
        >
          <sidebar-item
            :link="{
              name: $t('sidebar.appsettings'),
              icon: 'tim-icons icon-palette',
              path: '/settings/appsettings'
            }"
          ></sidebar-item>
          <sidebar-item
            :link="{
              name: $t('sidebar.customersettings'),
              icon: 'tim-icons icon-lock-circle',
              path: '/settings/customersettings'
            }"
          v-if="userRole !== 'User' && userRole !== 'WarehouseUser'"
          ></sidebar-item>
        </sidebar-item>
      </template>
    </side-bar>
    <!--Share plugin (for demo purposes). You can remove it if don't plan on using it-->
    <!-- <sidebar-share :background-color.sync="sidebarBackground"> </sidebar-share> -->
    <div class="main-panel" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>
      <router-view name="header"></router-view>

      <div
        :class="{ content: !$route.meta.hideContent }"
        @click="toggleSidebar"
      >
        <zoom-center-transition :duration="200" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </zoom-center-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import DashboardNavbar from './DashboardNavbar';
import ContentFooter from './ContentFooter';
import { ZoomCenterTransition } from 'vue2-transitions';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
    ZoomCenterTransition,
  },
  data() {
    return {
      sidebarBackground: 'tolsporagulur',
      userRole: null
    };
  },
  async created() {
    // Fetch the user's role from your authentication service when the component is created
    const token = Cookies.get('accessToken');
    const decodedToken = jwtDecode(token);
    this.userRole = decodedToken.role;
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    initScrollbar() {
      let docClasses = document.body.classList;
      let isWindows = navigator.platform.startsWith('Win');
      if (isWindows) {
        // if we are on windows OS we activate the perfectScrollbar function
        initScrollbar('sidebar');
        initScrollbar('main-panel');
        initScrollbar('sidebar-wrapper');

        docClasses.add('perfect-scrollbar-on');
      } else {
        docClasses.add('perfect-scrollbar-off');
      }
    },
  },
  mounted() {
    this.initScrollbar();
  }
};
</script>
<style lang="scss">
$scaleSize: 0.95;
@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  to {
    opacity: 1;
  }
}

.main-panel .zoomIn {
  animation-name: zoomIn95;
}

@keyframes zoomOut95 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.main-panel .zoomOut {
  animation-name: zoomOut95;
}
</style>
